import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import CardList from '../Components/CardList'
import SearchBox from '../Components/SearchBox';
import Scroll from '../Components/Scroll';
import ErrorBoundary from '../Components/ErrorBoundary'
import { setSearchField, requestUsers } from '../actions.js';

const mapStateToProps = (state) => {
	return {
		searchField: state.searchUsers.searchField,
		users: state.requestUsers.users,
		isPending: state.requestUsers.isPending,
		error: state.requestUsers.error
	}
}

const mapDispatchToPros = (dispatch) => {
	return {
		onSearchChange: (event) => dispatch(setSearchField(event.target.value)),
		onRequestUsers: () => dispatch(requestUsers())
	}
}

class App extends Component {

	componentDidMount() {
		this.props.onRequestUsers()
	}

	render() {

		const { searchField, onSearchChange, users, isPending } = this.props
		const filteredUsers = users.filter( user => {
			return user.name.toString().toLowerCase().includes(searchField.toLowerCase());
		});

		return isPending ?
		 <h1 className='tc'>Loading...</h1> :
		(
			<div className='tc'>
				<h1 className='f1'>Robofriends</h1>
				<SearchBox searchChange={onSearchChange}/>
				<Scroll>
					<ErrorBoundary>
						<CardList users={ filteredUsers }/>
					</ErrorBoundary>
				</Scroll>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToPros)(App);